.Home {
  text-align: center;
}

.Home-logo {
  height: 10vmin;
  pointer-events: none;
}

.Home-header {
  background-color: #8a9a23;
  min-height: 5vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Home-header img {
  float: left;
  width: 200px;
  height: 40px;
  background: #555;
}

.link {
  float: right;
  font-size: 15px;
  color: white;
  text-decoration: none;
}

.link:link {
  text-decoration: none;
}
.link:hover {
  text-decoration: none;
  color: white;
}

.header-table {
  width: 100%;
  border: 2px solid white;
}

.Home-details {
  margin-top: 2em;
  font-size: 12px;
  max-width: 50%;
}

.page-border {
  margin-top: 5em;
  font-size: 12px;
  max-width: 100%;
}
.component-border {
  margin-top: 4em;
  font-size: 12px;
  max-width: 100%;
}

.Home-details a {
  color: #61dafb;
}
.Home-link {
  margin-top: 0em;
  text-align: left;
  display: inline-block;
  text-decoration: none;
}

.container {
  width: 80%;
  margin: 0 auto; /* Center the DIV horizontally */
}
.fixed-header,
.fixed-footer {
  position: fixed;
  width: 100%;
  background: #8a9a23;
  padding: 10px 0;
  color: #fff;
}
.fixed-header {
  top: 0;
  background: #8a9a23;
}
.fixed-footer {
  bottom: 0;
}
/* Some more styles to beutify this example */
nav a {
  color: #fff;
  text-decoration: none;
  padding: 7px 25px;
  display: inline-block;
}
.container p {
  line-height: 50px; /* Create scrollbar to test positioning */
}

.search {
  background-color: #8a9a23;
  border: none;
  font-size: 12px;
  font-weight: 700;
  color: white;
  padding: 12px 20px;
  text-decoration: none;
  margin: 2px 1px;
  cursor: pointer;
}
.download {
  background-color: white;
  border: none;
  font-size: 12px;
  font-weight: 700;
  color: #8a9a23;
  padding: 1px 1px;
  text-decoration: none;
  margin: 20px 1px;
  cursor: pointer;
}
.download hover {
  text-decoration: none;
  color: white;
}
.select {
  display: block;
  font-size: 12px;
  font-weight: 700;
  color: #444;
  line-height: 1.3;
  padding: 0.6em 1.4em 0.5em 0.8em;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
  border-radius: 0.5em;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"),
    linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
}

.input {
  display: block;
  font-size: 12px;
  font-weight: 700;
  color: #444;
  line-height: 1.3;
  padding: 0.6em 1.4em 0.5em 0.8em;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
  border-radius: 0.5em;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
}

.table {
  overflow: visible;
}

.table-thick {
  border: 20px solid white;
}

.th-no-border {
  border-top: hidden !important;
  border-left: hidden !important;
  border-right: hidden !important;

  border: none;
  border-collapse: collapse;
}

.p-msg {
  font-size: 13px;
  font-weight: bold;
}

.messages {
  max-height: 300px;
  overflow: auto;
  background: #8a9a23;
  border: 1px solid #8a9a23;
  padding: 20px;
}

.messages ul {
  list-style-type: none;
  padding: 0px;
}

.messages li {
  padding: 4px;
  background: #dde6b5;
  margin-bottom: 4px;
  font-weight: 700;
  font-size: 16px;
  border-radius: 4px;
}

.options {
  float: left;
  clear: none;
  margin-top: 48px;
  display: flex;
  align-items: baseline;
}

.options.center {
  width: 100%;
  justify-content: center;
}

.options label {
  float: left;
  clear: none;
  padding: 0px 1em 0px 8px;
  font-size: 20px;
  font-weight: 500px;
}

.options input[type="radio"] {
  float: left;
  clear: none;
  margin: 2px 0 0 2px;
}
